import { all, fork } from "redux-saga/effects";
import { watchSecurityToken, watchRehydrate, watchUserLogout } from './securityToken'
import { watchGetAllUsers, watchRecoverUserPassword, watchChangeUserPassword, watchVerifyNewUser } from './users'
import { watchUpdateCompanyInformation, watchGetPermissionsForCompany, watchGetBregInfoForCompany,
  watchGetCompanyInfo, watchCreateNewUserInCompany, watchDeleteUserInCompany, watchUpdateUserInCompany, watchUpdateCompanySettings,
  watchRemoveModuleCompany, watchCreateModuleCompany, watchGetDepartments, watchCreateDepartment, watchUpdateDepartment, watchRemoveDepartment
}  from './company'
import { watchCreateAccount } from './account'
import { watchProjects, wacthProjectDocuments, watchProjectCreate, watchProjectDocumentCreate, watchDeleteProject, watchUpdateProject, watchEmployeesAtProject, watchGoodsConsumtionForProject, watchGetProjectMachineHours } from './project'
import { watchGetHours, watchDeleteHours, watchAddHour, watchAddTimeSheets, watchChangeHour, watchGetHoursBetweenDates, watchApproveHour, watchAddAbsenceHours, watchGetHourBank, watchGetProjectHours, watchResetHourSaved,
  watchResetApproved } from './hour'
import { watchActivityResetSaved, watchGetAllActivities, watchActivityCreate, watchActivityDelete, watchActivityUpdate} from './activity'
import { watchRoles, wacthChangeRoles, watchAddRoles, watchSaveRoles, watchUpdateRoles, watchDeleteRole }  from './role'
import { watchGetBregInfo } from './breg'
import { watchGetAllCustomers, watchCustomerCreate, watchCustomerUpdate, watchCustomerDelete, watchCustomerCreateFromArr} from './customer'
import { watchObservations, watchObservationCreate, watchDeleteObservation, watchUpdateObservation, watchCreateMeasurement, watchDeleteMeasurement} from './observation'
import { watchGetObservationTypes, watchGetDeviationCodes, watchCreateDeviationCodes, watchUpdateDeviationCodes, watchDeleteDeviationCodes, watchGetObservationCategories, watchGetProjectStatusTypes } from './setting'
import { watchGetCustomerLocations, watchGetAllLocations, watchLocationCreate, watchLocationDelete, watchLocationUpdate } from './location'
import { watchGetNews, watchNewsCreate, watchNewsDelete, watchNewsUpdate} from './news'
import { watchEquipments, watchDeleteEquipment, watchUpdateEquipment, watchCreateEquipment} from './equipment'
import { watchEquipmentTypes, watchDeleteEquipmentType, watchUpdateEquipmentType, watchCreateEquipmentType} from './equipmentType'
import { watchGetMeasurements, watchCreateMeasurements, watchUpdateMeasurement, watchDeleteMeasureMeasurement } from './measurement'
import { watchGetMeasureTemplate, watchMeasureTemplateCreate, watchMeasureTemplateDelete, watchMeasureTemplateUpdate } from './formTemplate'
import { watchCreateNotification, watchMarAsReadNotifications } from './notifications'
import { watchGetAllGoods, watchGoodsCreate, watchGoodsDelete, watchGoodsUpdate, watchGetAllGoodsCategories, watchGoodsCategoryCreate, watchGoodsUpdateCategory, watchGoodsDeleteCategory, watchCreateGoodsPrice} from './goods'
import { watchGetAllGoodsConsumptions, watchGoodsConsumptionCreate, watchGoodsConsumptionUpdate, watchDeleteConsumptionUpdate, watchApproveGoodsConsumption, watchGetGoodsConsumptionsByProject } from './goodsConsumption'
import {
  watchIntegrationPull,
  watchIntegrationPush,
  watchIntegrationIsAlive,
  watchIntegrationIsAliveReset,
  watchIntegrationSettingCreate,
  watchIntegrationSettingGet,
  watchIntegrationLogsGet,
  watchIntegrationActivitiesGet,
  watchIntegrationSalariesGet,
  watchIntegrationCreateActivity,
  watchIntegrationProjectsGet,
  watchIntegrationCreateProject,
  watchIntegrationEmployeesGet,
  watchIntegrationCreateEmployee,
  watchCreateProjectConnection,
  watchIntegrationCustomersGet,
  watchExportWorkHours,
  watchExportInvoices
}
from './integration'
import { watchGetMachines, watchDeleteMachine, watchUpdateMachine, watchCreateMachine} from './machine'
import { watchCreateChatMessage } from './chat'
import { watchGetSalaries, watchDeleteSalary, watchUpdateSalary, watchCreateSalary }  from './salary'
import uploaderSagas from './fileUpload'
import uploadFile from './multipartFormHandler'
import { watchGetAllHourRules, watchCreateHourRule, watchUpdateHourRule, watchDeleteHourRule } from './hourrule'
import { watchGetGroups, watchCreateGroup, watchUpdateGroup, watchDeleteGroup } from './group'
import { watchGetAllApprovalFlows, watchApprovalFlowsUpdate, watchApprovalFlowsDelete, watchApprovalFlowsCreate } from './approvalFlow'
import { watchGetEmployeeReport } from "./report";
import {
  watchCreateTemplate, watchDeleteTemplate, watchGetTemplates, watchUpdateTemplate, watchPromoteTemplate,
  watchCreateTemplateCategory, watchDeleteTemplateCategory, watchGetTemplateCategories, watchUpdateTemplateCategory, watchGetTemplateById
 } from './template'

 import {
  watchCreateTemplateResult,
  watchGetTemplateResults
 } from './templateResults'

 import { watchCreateTag, watchDeleteTag, watchGetTags, watchGetTagById } from "./tag";
 import { watchGetImageDayaBy } from "./imageData";

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([fork(watchSecurityToken),
    fork(watchGetMachines),
    fork(watchDeleteMachine),
    fork(watchUpdateMachine),
    fork(watchCreateMachine),
    fork(watchUserLogout),
    fork(watchGetAllUsers),
    fork(watchCreateChatMessage),
    fork(watchRecoverUserPassword),
    fork(watchChangeUserPassword),
    fork(watchVerifyNewUser),
    fork(watchGetPermissionsForCompany),
    fork(watchCreateAccount),
    fork(watchCreateModuleCompany),
    fork(watchRemoveModuleCompany),
    fork(watchGetCompanyInfo),
    fork(watchUpdateCompanySettings),
    fork(watchGetDepartments),
    fork(watchCreateDepartment),
    fork(watchRemoveDepartment),
    fork(watchUpdateDepartment),
    fork(watchUpdateCompanyInformation),
    fork(watchGetBregInfoForCompany),
    fork(watchCreateNewUserInCompany),
    fork(watchUpdateUserInCompany),
    fork(watchProjects),
    fork(watchEmployeesAtProject),
    fork(wacthProjectDocuments),
    fork(watchProjectCreate),
    fork(watchProjectDocumentCreate),
    fork(watchDeleteProject),
    fork(watchDeleteUserInCompany),
    fork(watchUpdateProject),
    fork(watchResetApproved),
    fork(watchResetHourSaved),
    fork(watchGetProjectMachineHours),
    fork(watchGetProjectHours),
    fork(watchGetHours),
    fork(watchGetHoursBetweenDates),
    fork(watchActivityResetSaved),
    fork(watchGetAllActivities),
    fork(watchActivityCreate),
    fork(watchActivityDelete),
    fork(watchActivityUpdate),
    fork(watchDeleteHours),
    fork(watchAddHour),
    fork(watchChangeHour),
    fork(watchApproveHour),
    fork(watchGetHourBank),
    fork(watchAddAbsenceHours),
    fork(watchAddTimeSheets),
    fork(watchDeleteRole),
    fork(watchRoles),
    fork(wacthChangeRoles),
    fork(watchAddRoles),
    fork(watchSaveRoles),
    fork(watchUpdateRoles),
    fork(watchGetBregInfo),
    fork(watchGetAllCustomers),
    fork(watchCustomerCreate),
    fork(watchCustomerCreateFromArr),
    fork(watchCustomerUpdate),
    fork(watchCustomerDelete),
    fork(watchObservations),
    fork(watchObservationCreate),
    fork(watchDeleteObservation),
    fork(watchUpdateObservation),
    fork(watchGetObservationTypes),
    fork(watchGetProjectStatusTypes),
    fork(watchGetDeviationCodes),
    fork(watchCreateDeviationCodes),
    fork(watchUpdateDeviationCodes),
    fork(watchDeleteDeviationCodes),
    fork(watchGetObservationCategories),
    fork(watchCreateMeasurement),
    fork(watchDeleteMeasurement),
    fork(watchGetCustomerLocations),
    fork(watchGetAllLocations),
    fork(watchLocationCreate),
    //fork(watchLocationPlaceDelete),
    fork(watchLocationDelete),
    fork(watchLocationUpdate),
    fork(watchGetNews),
    fork(watchNewsCreate),
    fork(watchNewsDelete),
    fork(watchNewsUpdate),
    fork(watchEquipments),
    fork(watchDeleteEquipment),
    fork(watchUpdateEquipment),
    fork(watchCreateEquipment),
    fork(watchEquipmentTypes),
    fork(watchDeleteEquipmentType),
    fork(watchUpdateEquipmentType),
    fork(watchCreateEquipmentType),
    fork(uploaderSagas, uploadFile),
    fork(watchGetMeasureTemplate),
    fork(watchMeasureTemplateCreate),
    fork(watchMeasureTemplateDelete),
    fork(watchMeasureTemplateUpdate),
    fork(watchGetTemplateById),
    fork(watchGetMeasurements),
    fork(watchCreateMeasurements),
    fork(watchUpdateMeasurement),
    fork(watchDeleteMeasureMeasurement),
    fork(watchIntegrationPull),
    fork(watchIntegrationPush),
    fork(watchIntegrationIsAlive),
    fork(watchIntegrationIsAliveReset),
    fork(watchIntegrationSettingCreate),
    fork(watchIntegrationSettingGet),
    fork(watchIntegrationLogsGet),
    fork(watchIntegrationActivitiesGet),
    fork(watchIntegrationSalariesGet),
    fork(watchIntegrationEmployeesGet),
    fork(watchIntegrationCreateActivity),
    fork(watchIntegrationProjectsGet),
    fork(watchIntegrationCreateProject),
    fork(watchIntegrationCreateEmployee),
    fork(watchIntegrationCustomersGet),
    fork(watchCreateProjectConnection),
    fork(watchExportWorkHours),
    fork(watchExportInvoices),
    fork(watchGetSalaries),
    fork(watchDeleteSalary),
    fork(watchUpdateSalary),
    fork(watchCreateSalary),
    fork(watchRehydrate),
    fork(watchCreateNotification),
    fork(watchMarAsReadNotifications),
    fork(watchGetAllGoods),
    fork(watchGoodsCreate),
    fork(watchGoodsDelete),
    fork(watchGoodsUpdate),
    fork(watchGetAllGoodsCategories),
    fork(watchGoodsCategoryCreate),
    fork(watchGoodsUpdateCategory),
    fork(watchGoodsDeleteCategory),
    fork(watchCreateGoodsPrice),
    fork(watchGetAllGoodsConsumptions),
    fork(watchGoodsConsumptionCreate),
    fork(watchGoodsConsumptionUpdate),
    fork(watchDeleteConsumptionUpdate),
    fork(watchGoodsConsumtionForProject),
    fork(watchApproveGoodsConsumption),
    fork(watchGetGoodsConsumptionsByProject),
    fork(watchGetAllHourRules),
    fork(watchCreateHourRule),
    fork(watchUpdateHourRule),
    fork(watchDeleteHourRule),
    fork(watchGetGroups),
    fork(watchCreateGroup),
    fork(watchUpdateGroup),
    fork(watchDeleteGroup),
    fork(watchGetAllApprovalFlows),
    fork(watchApprovalFlowsCreate),
    fork(watchApprovalFlowsUpdate),
    fork(watchApprovalFlowsDelete),
    fork(watchGetEmployeeReport),
    fork(watchDeleteTemplate),
    fork(watchGetTemplates),
    fork(watchUpdateTemplate),
    fork(watchCreateTemplate),
    fork(watchPromoteTemplate),
    fork(watchDeleteTemplateCategory),
    fork(watchGetTemplateCategories),
    fork(watchUpdateTemplateCategory),
    fork(watchCreateTemplateCategory),
    fork(watchCreateTemplateResult),
    fork(watchGetTagById),
    fork(watchGetTags),
    fork(watchDeleteTag),
    fork(watchCreateTag),
    fork(watchGetTemplateResults),
    fork(watchGetTemplateResults),
    fork(watchGetTemplateResults),
    fork(watchGetImageDayaBy)
  ]);
}