import axiosConfig from '../axiosConfig';

export default class TagsApi {
    constructor() {
        this.endpoint = `/Tag`;
    }

    async getTags() {
        try {
            return axiosConfig.get(`${this.endpoint}`);
        }
        catch (error) {
            throw error;
        }
    }

    async getTagById(id) {
        try {
            return axiosConfig.get(`${this.endpoint}/${id}`);
        }
        catch (error) {
            throw error;
        }
    }

    async createTag(tag) {
        try {
            return axiosConfig.post(this.endpoint, tag);
        }
        catch (error) {
            throw error;
        }
    }

    async deleteTag(tagId) {
        try {
            return axiosConfig.delete(`${this.endpoint}/${tagId}`);
        }
        catch(error) {
            throw error;
        }
    }
}