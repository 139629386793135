import { put, takeLatest, call } from 'redux-saga/effects'
import { ImageDataApi  } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import {
  GET_IMAGEDATA,
  GET_IMAGEDATA_SUCCESS,
  GET_IMAGEDATA_FAILURE,
 } from '../actions/constants'

export function* getImageDayaBy(action) {
    var api = new ImageDataApi();

    try {
      var payload = action.payload;

      const response = yield call(() => api.getImageDataFor(payload.userId, payload.projectId, payload.activityId, payload.templatesId));

      yield put({ type: GET_IMAGEDATA_SUCCESS, data: response.data });
    } catch (err) {
      yield put({ type: GET_IMAGEDATA_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
    }
  }

export function* watchGetImageDayaBy() {
  yield takeLatest(GET_IMAGEDATA, getImageDayaBy);
}
