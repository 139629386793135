import axiosConfig from '../axiosConfig';

export default class ImageDataApi {
    constructor() {
        this.endpoint = `/ImageData`;
    }

    async getImageDataFor(userId, projectId, activityId, templatesId) {
        try {
            let users = userId?.join(",") ?? [];
            let projects = projectId?.join(",") ?? [];
            let activities = activityId?.join(",") ?? [];
            let templates = templatesId?.join(",") ?? [];

            let usr = userId && userId.length !== 0 ? `userId=${users}&` : "";
            let proj = projectId && projectId.length !== 0 ? `projectId=${projects}&` : "";
            let act = activityId && activityId.length !== 0  ? `activityId=${activities}&` : "";
            let temp = templatesId && templatesId.length !== 0 ? `templatesId=${templates}` : "";


            let url = `${this.endpoint}/withFilter?${usr}${proj}${act}${temp}`;
            return axiosConfig.get(url);
        }
        catch(error) {
            throw error;
        }
    }
}