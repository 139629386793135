import { put, takeLatest, call} from 'redux-saga/effects'
import { CustomerApi, FileUploadApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'
import {
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  CREATE_CUSTOMER,
  CREATE_CUSTOMERFROMARR,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_SUCCESS
} from '../actions/constants'

export function* getAllCustomers() {
  var api = new CustomerApi();
  try {

    const response = yield call(() => api.getCustomers());
    yield put({ type: GET_CUSTOMER_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_CUSTOMER_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* createCustomer(action) {
  var api = new CustomerApi();
  var fileApi = new FileUploadApi();

  try {
    var data = action.payload;
    if (data.croppedImageData) {
      let response = yield call(() => fileApi.uploadFile(data.croppedImageData, "customer"));
      data.logoUrl = response.data.url;
    }

    var response = yield call(() => api.createCustomer(data));

    yield put({ type: CREATE_CUSTOMER_SUCCESS, data: response.data});
  } catch(err) {
    yield put({ type: CREATE_CUSTOMER_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });
  }
}

export function* createCustomerFromArr(action) {
  var api = new CustomerApi();

  try {

    let customers = action.payload;
    let isArray = Array.isArray(customers);
    let customerArr = isArray ? customers : [customers];

    for (let cust of customerArr) {
      let response = yield call(() => api.createCustomer(cust));

      yield put({ type: CREATE_CUSTOMER_SUCCESS, data: response.data });
    }
  } catch(err) {
    yield put({ type: CREATE_CUSTOMER_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });
  }
}

export function* deleteCustomer(action) {
  var api = new CustomerApi();

  try {
    var customerId = action.payload.customerId;

    yield call(() => api.deleteCustomer(customerId));

    yield put({ type: DELETE_CUSTOMER_SUCCESS, data: {id : customerId } });
  } catch(err) {
    yield put({ type: DELETE_CUSTOMER_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });
  }
}

export function* updateCustomer(action) {
  var api = new CustomerApi();
  var fileApi = new FileUploadApi();

  try {
    var data = action.payload;

    if (data.fileUrl && data.imageIsRecropped) {
      var cropResponse = yield call(() => fileApi.uploadFile(data.imageIsRecropped, "customer"));
      data.logoUrl = cropResponse.data.url;
    } else {
      data.logoUrl = data.fileUrl;
    }

    yield call(() => api.updateCustomer(data));

    yield put({ type: UPDATE_CUSTOMER_SUCCESS, data: data });
  } catch(err) {
    yield put({ type: UPDATE_CUSTOMER_FAILURE, data:{ error : handleCommonHttpErrors(err.response)} });
  }
}

export function* watchGetAllCustomers() {
  yield takeLatest(GET_CUSTOMER, getAllCustomers);
}

export function* watchCustomerCreate() {
  yield takeLatest(CREATE_CUSTOMER, createCustomer);
}

export function* watchCustomerCreateFromArr() {
  yield takeLatest(CREATE_CUSTOMERFROMARR, createCustomerFromArr);
}

export function* watchCustomerDelete() {
    yield takeLatest(DELETE_CUSTOMER, deleteCustomer);
}

export function* watchCustomerUpdate() {
  yield takeLatest(UPDATE_CUSTOMER, updateCustomer);
}