import { combineReducers } from "redux";
import { securityTokenReducer } from "./securityTokenReducer";
import { userReducer } from "./usersReducer"
import { permissionReducer } from './permissionReducer'
import { accountReducer, initialFormAccountReducer } from './accountReducer'
import { companyBregReducer } from './companyBregReducer'
import { topMenuReducer } from './topMenuReducer'
import { companyReducer } from './companyReducer'
import { projectReducer } from './projectReducer'
import { activityReducer } from './activityReducer'
import { hourReducer } from './hourReducer'
import { roleReducer} from './roleReducer'
import { namedStandardReducer } from './namedStandardReducer'
import { customerReducer } from './customerReducer'
import { observationReducer } from './observationReducer'
import { i18nextReducer } from 'i18next-redux-languagedetector';
import { settingReducer } from './settingReducer'
import { locationReducer} from './locationReducer'
import { newsReducer} from './newsReducer'
import { projectDocsReducer } from './projectDocsReducer'
import { GET_BREGINFO, GET_HOURBANK, GET_INTEGRATION_LOGS, USER_LOGOUT, GET_EMPLOYEEREPORT } from '../actions/constants'
import { equipmentReducer} from './equipmentReducer'
import { equipmentTypeReducer } from './equipmentTypeReducer'
import { fileUploadReducer} from './fileUploadReducer'
import { measurementReducer } from './measurementReducer'
import { formTemplateReducer } from './formTemplateReducer'
import { integrationReducer } from './integrationReducer'
import { integrationSettingReducer } from './integrationSettingReducer'
import { currentUserReducer } from './currentUserReducer'
import { salaryReducer  } from "./salaryReducer";
import { salaryFormReducer } from './salaryFormReducer'
import { notificationReducer } from './notificationReducer'
import { goodsReducer } from './goodsReducer'
import { goodsConsumptionReducer } from './goodsConsumptionReducer'
import { employeeAtProjectReducer } from './employeeAtProjectReducer'
import { guiReducer } from "./guiReducer";
import { chatReducer } from './chatReducer'
import { departmentReducer } from "./departmentReducer";
import { machineReducer } from './machineReducer'
import { hourRuleReducer } from "./hourRuleReducer";
import { groupReducer } from "./groupReducer";
import { approvalFlowReducer } from './approvalFlowReducer'
import { templateReducer } from './templateReducer'
import { templateCategoryReducer } from './templateCategoryReducer'
import { templateResultReducer } from './templateResultReducer'
import { tagsReducer } from "./tagsReducer";
import { imageDataReducer } from './imageDataReducer'

const appReducer = combineReducers({
  i18next: i18nextReducer,
  securityTokenReducer,
  userReducer,
  currentUserReducer,
  permissionReducer,
  accountReducer,
  companyBregReducer,
  initialFormAccountReducer,
  bregReducer : namedStandardReducer(GET_BREGINFO),
  reportReducer : namedStandardReducer(GET_EMPLOYEEREPORT),
  employeeAtProjectReducer,
  measurementReducer,
  projectDocsReducer,
  topMenuReducer,
  companyReducer,
  projectReducer,
  activityReducer,
  hourReducer,
  roleReducer,
  customerReducer,
  observationReducer,
  settingReducer,
  locationReducer,
  newsReducer,
  equipmentReducer,
  equipmentTypeReducer,
  fileUploadReducer,
  formTemplateReducer,
  integrationReducer,
  integrationSettingReducer,
  salaryReducer,
  salaryFormReducer,
  notificationReducer,
  goodsReducer,
  goodsConsumptionReducer,
  chatReducer,
  departmentReducer,
  machineReducer,
  integrationLogReducer : namedStandardReducer(GET_INTEGRATION_LOGS),
  hourBankReducer : namedStandardReducer(GET_HOURBANK),
  hourRuleReducer,
  groupReducer,
  approvalFlowReducer,
  templateReducer,
  templateCategoryReducer,
  templateResultReducer,
  tagsReducer,
  imageDataReducer,
  guiReducer
});

export const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action)
}