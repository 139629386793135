import { put, takeLatest, call } from 'redux-saga/effects'
import { TemplateApi  } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import {
  GET_TEMPLATE,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAILURE,
  GET_TEMPLATEBYID,
  GET_TEMPLATEBYID_SUCCESS,
  GET_TEMPLATEBYID_FAILURE,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE,
  PROMOTE_TEMPLATE,
  PROMOTE_TEMPLATE_SUCCESS,
  PROMOTE_TEMPLATE_FAILURE,
  GET_TEMPLATECATEGORY,
  GET_TEMPLATECATEGORY_SUCCESS,
  GET_TEMPLATECATEGORY_FAILURE,
  CREATE_TEMPLATECATEGORY,
  CREATE_TEMPLATECATEGORY_SUCCESS,
  CREATE_TEMPLATECATEGORY_FAILURE,
  DELETE_TEMPLATECATEGORY_SUCCESS,
  DELETE_TEMPLATECATEGORY_FAILURE,
  DELETE_TEMPLATECATEGORY,
  UPDATE_TEMPLATECATEGORY_SUCCESS,
  UPDATE_TEMPLATECATEGORY_FAILURE,
  UPDATE_TEMPLATECATEGORY
 } from '../actions/constants'
import { template } from 'lodash';

export function* getTemplates(action) {
  var api = new TemplateApi();

  try {
    let includeJson = action.payload.includeJson;
    let onlyPromoted = action.payload.onlyPromoted ?? false;
    const response = yield call(() => api.getTemplates(includeJson, onlyPromoted));

    yield put({ type: GET_TEMPLATE_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_TEMPLATE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getTemplateById(action) {
  var api = new TemplateApi();

  try {
    let id = action.payload.templateId;
    const response = yield call(() => api.getTemplateById(id));

    yield put({ type: GET_TEMPLATEBYID_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_TEMPLATEBYID_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createTemplate(action) {
    var api = new TemplateApi();

    try {

      let template = action.payload;
      var response  = yield call(() => api.createTemplate(template));

      yield put({ type: CREATE_TEMPLATE_SUCCESS, data: response.data });

    } catch(err) {
      yield put({ type: CREATE_TEMPLATE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
    }
}

export function* promoteTemplate(action) {
  var api = new TemplateApi();

  try {
    let payload = action.payload;

    const response = yield call(() => api.promoteTemplate(payload.templateId));

    //todo optimize getting only changed ones response.data + template.id + template.changedFrom
    yield call(getTemplates, {payload: {includeJson: true}});
    yield put({ type: PROMOTE_TEMPLATE_SUCCESS, data: response.data });

  } catch(err) {
    yield put({ type: PROMOTE_TEMPLATE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* deleteTemplate(action) {
var api = new TemplateApi();

  try {

    var payload = action.payload;
    let templateId = payload.templateId;
    let templateCategoryId = payload.templateCategoryId;

    yield call(() => api.deleteTemplate(templateId, templateCategoryId));

    yield put({ type: DELETE_TEMPLATE_SUCCESS, data: templateId });
  } catch(err) {
    yield put({ type: DELETE_TEMPLATE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* updateTemplate(action) {
    var api = new TemplateApi();

  try {
    var data = action.payload;

    yield call(() => api.updateTemplate(data));

    yield put({ type: UPDATE_TEMPLATE_SUCCESS, data: data });
  } catch(err) {
    yield put({ type: UPDATE_TEMPLATE_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getTemplateCategories() {
  var api = new TemplateApi();

  try {
    const response = yield call(() => api.getTemplateCategories());

    yield put({ type: GET_TEMPLATECATEGORY_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_TEMPLATECATEGORY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createTemplateCategory(action) {
    var api = new TemplateApi();

    try {
      let templateCategory = action.payload;

      yield call(() => api.createTemplateCategory(templateCategory));
      yield put({ type: CREATE_TEMPLATECATEGORY_SUCCESS, data: templateCategory });

    } catch(err) {
      yield put({ type: CREATE_TEMPLATECATEGORY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
    }
}

export function* deleteTemplateCategory(action) {
  var api = new TemplateApi();

  try {
    var templateCategoryId = action.payload;

    yield call(() => api.deleteTemplateCategory(templateCategoryId));

    yield put({ type: DELETE_TEMPLATECATEGORY_SUCCESS, data: templateCategoryId });
  } catch(err) {
    yield put({ type: DELETE_TEMPLATECATEGORY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* updateTemplateCategory(action) {
    var api = new TemplateApi();

  try {
    var data = action.payload;

    yield call(() => api.updateTemplateCategory(data));

    yield put({ type: UPDATE_TEMPLATECATEGORY_SUCCESS, data: data });
  } catch(err) {
    yield put({ type: UPDATE_TEMPLATECATEGORY_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   });
  }
}


export function* watchGetTemplates() {
  yield takeLatest(GET_TEMPLATE, getTemplates);
}

export function* watchGetTemplateById() {
  yield takeLatest(GET_TEMPLATEBYID, getTemplateById);
}

export function* watchDeleteTemplate() {
  yield takeLatest(DELETE_TEMPLATE, deleteTemplate);
}

export function* watchUpdateTemplate() {
  yield takeLatest(UPDATE_TEMPLATE, updateTemplate);
}

export function* watchCreateTemplate() {
    yield takeLatest(CREATE_TEMPLATE, createTemplate);
}

export function* watchPromoteTemplate() {
  yield takeLatest(PROMOTE_TEMPLATE, promoteTemplate);
}

export function* watchGetTemplateCategories() {
  yield takeLatest(GET_TEMPLATECATEGORY, getTemplateCategories);
}

export function* watchDeleteTemplateCategory() {
  yield takeLatest(DELETE_TEMPLATECATEGORY, deleteTemplateCategory);
}

export function* watchUpdateTemplateCategory() {
  yield takeLatest(UPDATE_TEMPLATECATEGORY, updateTemplateCategory);
}

export function* watchCreateTemplateCategory() {
    yield takeLatest(CREATE_TEMPLATECATEGORY, createTemplateCategory);
}