import {
    GET_IMAGEDATA_SUCCESS,
    GET_IMAGEDATA_FAILURE,
    GET_IMAGEDATA,
  } from '../actions/constants'

  const initialState = {
    data: [],
    error: null,
    loading: false,
    saved : false,
  };

  export const imageDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_IMAGEDATA:
        return {...state, ...{loading: true, error: null, saved : false}}

      case GET_IMAGEDATA_SUCCESS :
        return {...state, ...{loading: false, saved : false, data : action.data, error: ''}}

      case GET_IMAGEDATA_FAILURE:
        return {...state, loading: false, saved : false, error : action.data.error };
      default:
        return state;
    }
  };