import { put, takeLatest, call } from 'redux-saga/effects'
import { TagsApi  } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  GET_TAGSBYID,
  GET_TAGSBYID_SUCCESS,
  GET_TAGSBYID_FAILURE,
  CREATE_TAGS,
  CREATE_TAGS_SUCCESS,
  CREATE_TAGS_FAILURE,
  DELETE_TAGS_SUCCESS,
  DELETE_TAGS_FAILURE,
  DELETE_TAGS,
 } from '../actions/constants'

export function* getAllTags() {
  var api = new TagsApi();

  try {
    const response = yield call(() => api.getTags());

    yield put({ type: GET_TAGS_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_TAGS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getTagById(action) {
    var api = new TagsApi();

    try {
      var tagId = action.payload;
      const response = yield call(() => api.getTagById(tagId));

      yield put({ type: GET_TAGSBYID_SUCCESS, data: response.data });
    } catch (err) {
      yield put({ type: GET_TAGSBYID_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
    }
  }

export function* createTag(action) {
    let api = new TagsApi();

    try {
        var tag = action.payload;

        const response = yield call(() => api.createTag(tag));

        yield put({ type: CREATE_TAGS_SUCCESS, data: response.data });
    } catch(err) {
      yield put({ type: CREATE_TAGS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
    }
  }


export function* deleteTag(action) {
  var api = new TagsApi();

  try {
    var tag = action.payload;

    yield call(() => api.deleteTag(tag));

    yield put({ type: DELETE_TAGS_SUCCESS, data: tag });
  } catch(err) {
    console.log(err)
    yield put({ type: DELETE_TAGS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* watchGetTags() {
  yield takeLatest(GET_TAGS, getAllTags);
}

export function* watchGetTagById() {
    yield takeLatest(GET_TAGSBYID, getTagById);
}

export function* watchDeleteTag() {
  yield takeLatest(DELETE_TAGS, deleteTag);
}

export function* watchCreateTag() {
    yield takeLatest(CREATE_TAGS, createTag);
}