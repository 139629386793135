
var loginInProgress = '';

export function setLoginInProgress(val) {
    loginInProgress = val;
}

export function getLoginInProgress() {
    return loginInProgress;
}

export function getSupportedLanguages() {
    var lang =
        [
            { name: "Norwegian", code: "nb" },
            { name: "English", code: "en" }
        ];

    return lang;
}

export const HOURANDMINUTE = "HOURANDMINUTE";
export const HOURANDMINUTESASFRACTION = "HOURANDMINUTESASFRACTION";
export const NOTFOUND = -1;

export const DATEVIEWFORMAT = 'dd.MM.yyyy'