import {
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CREATE_USERINCOMPANY,
  CREATE_USERINCOMPANY_SUCCESS,
  CREATE_USERINCOMPANY_FAILURE
  } from '../actions/constants'

const initialState = {
  data: [],
  error: null,
  loading: false,
  saved : false
};


export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_USER:
    case GET_USERS:
      return {...state, ...{loading: true, error : null, saved : false}}
    case GET_USERS_SUCCESS:
      return {...state, ...{loading: false, error : '', data : [...action.data]}}
    case GET_USERS_FAILURE:
      return {...state, loading: false, error : "" };
    case DELETE_USER_SUCCESS: {
      const list = state.data.filter(item => item.userId !== action.data);
      return {...state, ...{loading: false, error : '', data : list}}
    }
    case DELETE_USER_FAILURE:
      return {...state, loading: false, error : action.data.error };
    case CREATE_USERINCOMPANY:
      return {...state, ...{loading: true, error : null, saved : false}};
    case CREATE_USERINCOMPANY_SUCCESS:
      var item = action.data;
      var newArr = state.data.concat(item);
      return {...state, ...{loading: false, error : '', data : newArr, saved : true}};
    case CREATE_USERINCOMPANY_FAILURE:
      return {...state, loading: false, error : action.data.error, saved : false };
    case UPDATE_USER:
      return {...state, ...{loading: true, error : null, saved : false}}
    case UPDATE_USER_SUCCESS: {
      let newArr = [...state.data];
      let index = newArr.findIndex(item => item.userId === action.data.userId);

      if (index >= 0)
          newArr.splice(index, 1, action.data)

      return {...state, ...{loading: false, error : '', data : newArr, saved : true}}
    }
    case UPDATE_USER_FAILURE:
      return {...state, loading: false, error : action.data.error, saved : false };
    default:
      return state;
  }
};