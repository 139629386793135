import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { objectByString } from '../../utils/objectByString'

export const useSettings = (settingToCheck, val = true, type = "companysettings") => {

  const { error, settings } = useSelector(state => state.currentUserReducer);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (error) return;
    if (!settings || settings.length === 0 || !settingToCheck || settingToCheck.length === 0)
    {
      setEnabled(false);
      return;
    }

    let foundSetting = settings.find(item => item.type === type);
    if (!foundSetting) {
      setEnabled(false);
      return;
    }

    const settingObject = JSON.parse(foundSetting.value);
    const isSettingEnabled = objectByString(settingObject, settingToCheck);

    if (isSettingEnabled === val) {
        setEnabled(true);
    } else {
        setEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])

  return {
    error,
    enabled
  }
};