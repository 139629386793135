import {
    GET_TAGS_SUCCESS,
    GET_TAGS_FAILURE,
    GET_TAGS,
    CREATE_TAGS_SUCCESS,
    CREATE_TAGS_FAILURE,
    CREATE_TAGS,
    DELETE_TAGS,
    DELETE_TAGS_SUCCESS,
    DELETE_TAGS_FAILURE,
    CLEAR_TAGSSAVED
  } from '../actions/constants'

  const initialState = {
    data: [],
    error: null,
    loading: false,
    saved : false,
  };


  export const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TAGS:
      case CREATE_TAGS:
      case DELETE_TAGS:
        return {...state, ...{loading: true, error: null, saved : false}}

      case GET_TAGS_SUCCESS :
        return {...state, ...{loading: false, saved : false, data : action.data, error: ''}}
      case CREATE_TAGS_SUCCESS : {
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, saved : true, data : newArr, error: ''}}
      }
      case DELETE_TAGS_SUCCESS: {
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.tagId === action.data);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, saved : false, data : copyArr, error: ''}}
      }
      case GET_TAGS_FAILURE:
      case CREATE_TAGS_FAILURE:
      case DELETE_TAGS_FAILURE:
        return {...state, loading: false, saved : false, error : action.data.error };
      case CLEAR_TAGSSAVED:
        return {...state, saved : false };
      default:
        return state;
    }
  };