import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class ObservationApi {
    constructor() {
        this.endpoint = `/Observation`;
    }


    async getAllObservations() {
        try {
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }
    }

    async createNewObservation(observation) {
        try {
            return axiosConfig.post(this.endpoint, observation);
        }
        catch(error) {
            throw error;
        }
    }

    async deleteObservationById(observation) {
        try {
            return axiosConfig.delete(`${this.endpoint}/${observation.observationId}?projectId=${observation.projectId}`);
        }
        catch(error) {
            throw error;
        }
    }

    async updateObservation(observation) {
        try {
            return axiosConfig.put(`${this.endpoint}/${observation.observationId}`, observation)
        }
        catch(error) {
            throw error;
        }
    }
}

mock.onPost("Observation").reply(200, {
});

mock.onPut(/Observation\/.+/).reply(200, {

});


mock.onDelete(/Observation\?id.+/).reply(200);

  mock.onGet(/Observation\?projectId.+/).reply(200,
    [{
        observationId : "a3636c70-cd1c-11ea-87d0-0242ac130003",
        projectId : "a3636c70-cd1c-11ea-87d0-0242ac130011",
        type : "HIGH",
        number : "1001",
        title : "Defekt sikringsskap",
        description : "Sikringskap er brannfarlig. Dette er en hazard",
        dateTime : "2020-01-02",
        measurements : [{
            fieldId : "af4f45e5-3ca0-4d57-814b-6cca930290ff",
            version : "1",
            fieldType : "textboxwithunit",
            value : "0.2 Ohm",
            unit : "",
            text : "Motstand",
            description : "",
          },
          {
            fieldId : "d44f45e5-3ca0-4d57-814b-6cca930290ff",
            version : "1",
            fieldType : "textboxwithunit",
            value : "22.2 Watt",
            unit : "",
            text : "Effekt",
            description : "",
          }]

    },
    {
        observationId : "b3636c70-cd1c-11ea-87d0-0242ac130003",
        projectId : "b3636c70-cd1c-11ea-87d0-0242ac130011",
        type : "CRITICAL",
        number : "2001",
        title : "Sikringsskap brenner",
        description : "Sikringskap brenner. Må gjøres noe med",
        dateTime : "2020-01-02",
        measurements : [{
            fieldId : "af4f45e5-3ca0-ad57-814b-6cca930290ff",
            version : "1",
            fieldType : "textboxwithunit",
            value : "0.2 Ohm",
            unit : "",
            text : "Motstand",
            description : "",
          },
          {
            fieldId : "d44f45e5-3ca0-gd57-814b-6cca930290ff",
            version : "1",
            fieldType : "textboxwithunit",
            value : "22.2 Volt",
            unit : "",
            text : "Spenning",
            description : "",
          }]
    }]
);

// mock.onGet(/Observation\?projectId.+/).reply(200,
//     [{
//         observationId : "a3636c70-cd1c-11ea-87d0-0242ac130003",
//         projectId : "a3636c70-cd1c-11ea-87d0-0242ac130011",
//         type : "HIGH",
//         number : "1001",
//         title : "Defekt sikringsskap",
//         description : "Sikringskap er brannfarlig. Dette er en hazard",
//         dateTime : "2020-01-02",
//         measurements : [
//             { name : "Strøm", value : "2 A", measurementId : "d44f45e5-3ca0-4d57-814b-6cca930290ff"},
//             { name : "Spenning", value : "220 V", measurementId : "a44f45e5-3ca0-4d57-814b-6cca930290ff"}
//         ]
//     },
//     {
//         observationId : "b3636c70-cd1c-11ea-87d0-0242ac130003",
//         projectId : "b3636c70-cd1c-11ea-87d0-0242ac130011",
//         type : "CRITICAL",
//         number : "2001",
//         title : "Sikringsskap brenner",
//         description : "Sikringskap brenner. Må gjøres noe med",
//         dateTime : "2020-01-02",
//         measurements : [
//             { name : "Måling31", value : "120", measurementId : "b44f45e5-3ca0-4d57-814b-6cca930290ff"},
//             { name : "Måling32", value : "110", measurementId : "c44f45e5-3ca0-4d57-814b-6cca930290ff"}
//         ]
//     }]
// );

mock.onGet("/Observation").reply(200,
    [{
        observationId : "a3636c70-cd1c-11ea-87d0-0242ac130003",
        projectId : "a3636c70-cd1c-11ea-87d0-0242ac130011",
        type : "HIGH",
        number : "1001",
        title : "Defekt sikringsskap",
        description : "Sikringskap er brannfarlig. Dette er en hazard",
        dateTime : "2020-01-02",
        measurements : [{
            fieldId : "af4f45e5-3ca0-4d57-814b-6cca930290ff",
            version : "1",
            fieldType : "textboxwithunit",
            value : "0.2 Ohm",
            unit : "",
            text : "Motstand",
            description : "",
          },
          {
            fieldId : "d44f45e5-3ca0-4d57-814b-6cca930290ff",
            version : "1",
            fieldType : "textboxwithunit",
            value : "22.2 Watt",
            unit : "",
            text : "Effekt",
            description : "",
          }]
    },
    {
        observationId : "b3636c70-cd1c-11ea-87d0-0242ac130003",
        projectId : "b3636c70-cd1c-11ea-87d0-0242ac130011",
        type : "CRITICAL",
        number : "2001",
        title : "Sikringsskap brenner",
        description : "Sikringskap brenner. Må gjøres noe med",
        dateTime : "2020-01-02",
        measurements : [{
            fieldId : "af4f45e5-3ca0-ad57-814b-6cca930290ff",
            version : "1",
            fieldType : "textboxwithunit",
            value : "0.2 Ohm",
            unit : "",
            text : "Motstand",
            description : "",
          },
          {
            fieldId : "d44f45e5-3ca0-gd57-814b-6cca930290ff",
            version : "1",
            fieldType : "textboxwithunit",
            value : "22.2 Volt",
            unit : "",
            text : "Spenning",
            description : "",
          }]
    }]
);

