import { put, takeLatest, call } from 'redux-saga/effects'
import { MachineApi, FileUploadApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import {
  GET_MACHINE,
  GET_MACHINE_SUCCESS,
  GET_MACHINE_FAILURE,
  CREATE_MACHINE,
  CREATE_MACHINE_SUCCESS,
  CREATE_MACHINE_FAILURE,
  DELETE_MACHINE_SUCCESS,
  DELETE_MACHINE_FAILURE,
  DELETE_MACHINE,
  UPDATE_MACHINE_SUCCESS,
  UPDATE_MACHINE_FAILURE,
  UPDATE_MACHINE
 } from '../actions/constants'

export function* getAllMachines() {
  var api = new MachineApi();

  try {
    const response = yield call(() => api.getMachines());

    yield put({ type: GET_MACHINE_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_MACHINE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createMachine(action) {
    var api = new MachineApi();
    var fileApi = new FileUploadApi();

    try {
      var machine = action.payload.machine;
      var extra = action.payload.extra;

      if (extra.croppedImageData) {
        var cropResponse = yield call(() => fileApi.uploadFile(extra.croppedImageData, "equipment"));
        machine.logoUrl = cropResponse.data.url;
      }

      yield call(() => api.createMachine(machine));

      yield put({ type: CREATE_MACHINE_SUCCESS, data: machine });
    } catch(err) {
      yield put({ type: CREATE_MACHINE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
    }
  }


export function* deleteMachine(action) {
  var api = new MachineApi();

  try {
    var machine = action.payload;

    yield call(() => api.deleteMachine(machine.equipmentId));

    yield put({ type: DELETE_MACHINE_SUCCESS, data: machine.equipmentId });
  } catch(err) {
    yield put({ type: DELETE_MACHINE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* updateMachine(action) {
  var api = new MachineApi();
  var fileApi = new FileUploadApi();

  try {
    var machine = action.payload.machine;
    var extra = action.payload.extra;

    if (extra.croppedImageData) {
      var cropResponse = yield call(() => fileApi.uploadFile(extra.croppedImageData, "equipment"));
      machine.logoUrl = cropResponse.data.url;
    }

    yield call(() => api.updateMachine(machine));

    yield put({ type: UPDATE_MACHINE_SUCCESS, data: machine });
  } catch(err) {
    yield put({ type: UPDATE_MACHINE_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* watchGetMachines() {
  yield takeLatest(GET_MACHINE, getAllMachines);
}

export function* watchDeleteMachine() {
  yield takeLatest(DELETE_MACHINE, deleteMachine);
}

export function* watchUpdateMachine() {
  yield takeLatest(UPDATE_MACHINE, updateMachine);
}

export function* watchCreateMachine() {
    yield takeLatest(CREATE_MACHINE, createMachine);
}