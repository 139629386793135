import {
  DELETE_DEPARTMENTS,
  DELETE_DEPARTMENTS_SUCCESS,
  DELETE_DEPARTMENTS_FAILURE,
  UPDATE_DEPARTMENTS,
  UPDATE_DEPARTMENTS_SUCCESS,
  UPDATE_DEPARTMENTS_FAILURE,
  CREATE_DEPARTMENTS,
  CREATE_DEPARTMENTS_SUCCESS,
  CREATE_DEPARTMENTS_FAILURE,
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAILURE,
  GET_COMPANYPERMISSIONS,
  GET_COMPANYPERMISSIONS_SUCCESS,
  GET_COMPANYPERMISSIONS_FAILURE,
  GET_COMPANYBREGINFO,
  GET_COMPANYBREGINFO_SUCCESS,
  GET_COMPANYBREGINFO_FAILURE,
  GET_COMPANYINFO,
  GET_COMPANYINFO_SUCCESS,
  GET_COMPANYINFO_FAILURE,
  UPDATE_COMPANYINFO,
  UPDATE_COMPANYINFO_SUCCESS,
  UPDATE_COMPANYINFO_FAILURE,
  CREATE_USERINCOMPANY,
  CREATE_USERINCOMPANY_SUCCESS,
  CREATE_USERINCOMPANY_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_CURRENTUSER,
  UPDATE_COMPANYSETTING,
  UPDATE_COMPANYSETTING_SUCCESS,
  UPDATE_COMPANYSETTING_FAILURE,
  CREATE_MODULEFORCOMPANY,
  CREATE_MODULEFORCOMPANY_SUCCESS,
  CREATE_MODULEFORCOMPANY_FAILURE,
  DELETE_MODULEFORCOMPANY,
  DELETE_MODULEFORCOMPANY_SUCCESS,
  DELETE_MODULEFORCOMPANY_FAILURE,
  RESET_COMPANYSETTING,
  REMOVE_CURRENTUSERMODULESETTING
 } from '../actions/constants'

import { put, takeLatest, call, select} from 'redux-saga/effects'
import { CompanyApi, FileUploadApi, UserApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'
import { getSecurityContextFromStore } from './securityToken'
import { getCurrentUserSetting, getCurrentUserFromStore } from './users'
import { setLoggedInUsersAbilities } from '../access/auth'

export function* getAllPermissionsForCompany(action) {
  var api = new CompanyApi();

  try {
        const permissionResponse = yield call(() => api.getAllPermissionsForCompany(action.payload.companyId));
        const roleResponse = yield call( () => api.getRoles(action.payload.companyId));

        yield put({ type: GET_COMPANYPERMISSIONS_SUCCESS, data: {permissions : permissionResponse.data, roles : roleResponse.data }}); //To the Reducer
    } catch(err) {
        yield put({ type: GET_COMPANYPERMISSIONS_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });
  }
}

export function* getBregInfoForCompany() {
  var api = new CompanyApi();

  try {
        const bregInfoResponse = yield call(() => api.getBregCompanyInformation(getCompanyId()));

        yield put({ type: GET_COMPANYBREGINFO_SUCCESS, data: bregInfoResponse.data});
    } catch(err) {
        yield put({ type: GET_COMPANYBREGINFO_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });
  }
}

export function* getCompanyInformation() {
  var api = new CompanyApi();

  try {
    const { data : companyInfo } = yield call(() => api.getCompanyInfo());
    const { data : companySetting} = yield call(() => api.getCompanySettings(companyInfo.companyId))

    yield put({ type: GET_COMPANYINFO_SUCCESS, data: {companyInfo, companySetting}});
  } catch(err) {
    yield put({ type: GET_COMPANYINFO_FAILURE, data: {error : handleCommonHttpErrors(err.response)}} );
  }
}

export function* getDepartments() {
  var api = new CompanyApi();

  try {
    let companyId = yield getCompanyId();
    let response = yield call(() => api.getDepartments(companyId))

    yield put({ type: GET_DEPARTMENTS_SUCCESS, data: response.data});
  } catch(err) {
    yield put({ type: GET_DEPARTMENTS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}} );
  }
}

export function* updateCompanySettings(action) {
  var api = new CompanyApi();
  try {
    var companySetting = action.payload;

    if (companySetting.companySettingId) {
      yield call(() => api.updateCompanySettings(companySetting.companyId, companySetting));
    } else {
      let response = yield call(() => api.createCompanySettings(companySetting.companyId, companySetting));
      companySetting.companySettingId = response.data;
    }

    yield call(getCurrentUserSetting, {})

    yield put({ type: UPDATE_COMPANYSETTING_SUCCESS, data: {companySetting}});
  } catch(err) {
    yield put({ type: UPDATE_COMPANYSETTING_FAILURE, data: {error : handleCommonHttpErrors(err.response)}} );
  }
}

export function* updateCompanyInformation(action) {
  var api = new CompanyApi();
  var fileUploadApi = new FileUploadApi();

  try {
    var companyData = action.payload;

    let url = companyData.logoUrl;
    let fileId = companyData.fileId;

    if (companyData.croppedImage) {
      let response = yield call(() => fileUploadApi.uploadFile(companyData.croppedImage, "user"));
      url = response.data.url;
      fileId = response.data.fileId;
    }

    companyData.logoUrl = url;
    companyData.fileId = fileId;

    yield call(() => api.updateCompanyInfo(companyData.companyId, companyData));
    const {data : companyResponse } = yield call(() => api.getCompanyInfo());

    yield put({ type: UPDATE_COMPANYINFO_SUCCESS, data: {companyInfo : companyResponse}});
    yield put({ type: RESET_COMPANYSETTING, data: {}});

  } catch(err) {
    yield put({ type: UPDATE_COMPANYINFO_FAILURE, data: {error : handleCommonHttpErrors(err.response)}} );
  }
}

export function* updateUserInCompany(action) {
  var api = new CompanyApi();
  var userApi = new UserApi();
  var fileApi = new FileUploadApi();

  try {
    var data = action.payload;
    let companyId = yield getCompanyId();

    let avatar = data.fileUrl;
    if (data.croppedImageData) {
      let response = yield call(() => fileApi.uploadFile(data.croppedImageData, "user"));
      avatar = response.data.url;
    }

    data.avatarUrl = avatar ?? data.avatarUrl;

    yield call(() => userApi.updateUserRoles(data.userId, data.roles));
    yield call(() => api.updateUserInCompany(companyId, data));

    yield put({ type: UPDATE_USER_SUCCESS, data: data}); //To the Reducer
    yield put({ type: UPDATE_CURRENTUSER, data: data}); //To the Reducer
  } catch(err) {
    yield put({ type: UPDATE_USER_FAILURE, data: {error : handleCommonHttpErrors(err.response)}});
  }
}

export function* updateDepartment(action) {
  var api = new CompanyApi();

  try {

    var data = action.payload;
    yield call(() => api.updateDepartment(data))

    yield put({ type: UPDATE_DEPARTMENTS_SUCCESS, data: data});
  } catch(err) {
    yield put({ type: UPDATE_DEPARTMENTS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}} );
  }
}

export function* deleteDepartment(action) {
  var api = new CompanyApi();

  try {

    var data = action.payload;
    let companyId = yield getCompanyId();
    yield call(() => api.deleteDepartment(companyId, data.departmentId))

    yield put({ type: DELETE_DEPARTMENTS_SUCCESS, data: data});
  } catch(err) {
    yield put({ type: DELETE_DEPARTMENTS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}} );
  }
}

export function* createDepartment(action) {
  var api = new CompanyApi();

  try {

    var data = action.payload;
    let companyId = yield getCompanyId();
    let response = yield call(() => api.createDepartment(companyId, data))

    yield put({ type: CREATE_DEPARTMENTS_SUCCESS, data: response.data});
  } catch(err) {
    yield put({ type: CREATE_DEPARTMENTS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}} );
  }
}

export function* createModuleForCompany(action) {
  var api = new CompanyApi();

  try {
    var data = action.payload;

    let companyId = yield getCompanyId();

    let response = yield call(() => api.createCompanyModuleAccess(companyId, data.moduleName));
    yield put({ type: CREATE_MODULEFORCOMPANY_SUCCESS, data: response.data});

    //refresh permission and rights
    yield updateUserAbilities();

  } catch(err) {
    yield put({ type: CREATE_MODULEFORCOMPANY_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });
  }
}

export function* createNewUserInCompany(action) {
  var api = new CompanyApi();

  try {

    var data = action.payload;
    let response = yield call(() => api.createUserInCompany(data.companyId, data.userStruct));

    yield put({ type: CREATE_USERINCOMPANY_SUCCESS, data: response.data}); //To the Reducer
  } catch(err) {
    yield put({ type: CREATE_USERINCOMPANY_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });
  }
}

export function* deleteUserFromCompany(action) {
  var api = new CompanyApi();

  try {
    var userId = action.payload;

    let companyId = yield getCompanyId();
    yield call(() => api.deleteUserFromCompany(companyId, userId));

    yield put({ type: DELETE_USER_SUCCESS, data: userId});
  } catch(err) {
    yield put({ type: DELETE_USER_FAILURE, data: {error : handleCommonHttpErrors(err.response)}} );
  }
}

export function* deleteModuleForCompany(action) {
  var api = new CompanyApi();

  try {
    var data = action.payload;

    let companyId = yield getCompanyId();

    yield call(() => api.removeCompanyModuleAccess(companyId, data.modulesForCompanyId));
    yield put({ type: REMOVE_CURRENTUSERMODULESETTING, data: data.modulesForCompanyId});

    //refresh permission and rights
    yield updateUserAbilities();

    yield put({ type: DELETE_MODULEFORCOMPANY_SUCCESS, data: data.modulesForCompanyId});
  } catch(err) {
    yield put({ type: DELETE_MODULEFORCOMPANY_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });
  }
}

function* updateUserAbilities() {
    let user = yield select(getCurrentUserFromStore);
    setLoggedInUsersAbilities(user.abilities, user.modules);
}

function* getCompanyId() {
  let context = yield select(getSecurityContextFromStore);
  return context.companyId;
}

export function* watchGetDepartments() {
  yield takeLatest(GET_DEPARTMENTS, getDepartments);
}

export function* watchGetPermissionsForCompany() {
  yield takeLatest(GET_COMPANYPERMISSIONS, getAllPermissionsForCompany);
}

export function* watchGetBregInfoForCompany() {
  yield takeLatest(GET_COMPANYBREGINFO, getBregInfoForCompany);
}

export function* watchGetCompanyInfo() {
  yield takeLatest(GET_COMPANYINFO, getCompanyInformation);
}

export function* watchUpdateCompanyInformation() {
  yield takeLatest(UPDATE_COMPANYINFO, updateCompanyInformation);
}

export function* watchUpdateCompanySettings() {
  yield takeLatest(UPDATE_COMPANYSETTING, updateCompanySettings);
}

export function* watchCreateDepartment() {
  yield takeLatest(CREATE_DEPARTMENTS, createDepartment);
}

export function* watchCreateModuleCompany() {
  yield takeLatest(CREATE_MODULEFORCOMPANY, createModuleForCompany);
}

export function* watchCreateNewUserInCompany() {
  yield takeLatest(CREATE_USERINCOMPANY, createNewUserInCompany);
}

export function* watchDeleteUserInCompany() {
  yield takeLatest(DELETE_USER, deleteUserFromCompany);
}

export function* watchUpdateDepartment() {
  yield takeLatest(UPDATE_DEPARTMENTS, updateDepartment);
}

export function* watchUpdateUserInCompany() {
  yield takeLatest(UPDATE_USER, updateUserInCompany);
}

export function* watchRemoveModuleCompany() {
  yield takeLatest(DELETE_MODULEFORCOMPANY, deleteModuleForCompany);
}

export function* watchRemoveDepartment() {
  yield takeLatest(DELETE_DEPARTMENTS, deleteDepartment);
}
